import React from "react";
import './HowToUse.css';

// Import images
import parking1Jpg from '../../images/parking1.jpg';
import parking1Webp480 from '../../images/parking1-480w.webp';
import parking1Webp768 from '../../images/parking1-768w.webp';
import parking1Webp1024 from '../../images/parking1-1024w.webp';

import parking2Jpg from '../../images/parking2.jpg';
import parking2Webp480 from '../../images/parking2-480w.webp';
import parking2Webp768 from '../../images/parking2-768w.webp';
import parking2Webp1024 from '../../images/parking2-1024w.webp';

import parking3Jpg from '../../images/parking3.jpg';
import parking3Webp480 from '../../images/parking3-480w.webp';
import parking3Webp768 from '../../images/parking3-768w.webp';
import parking3Webp1024 from '../../images/parking3-1024w.webp';

const HowToUse: React.FC = () => {
  return (
    <section id="howtouse">
      <article className="textCard">
        <div className="textCardText">
          <h2 className="header">Rezervuokite vietą automobiliui</h2>
          <p className="body">
            Rezervacijos skiltyje pasirinkite automobilio palikimo ir pasiėmimo datas. Nurodę datas, spauskite „Tikrinti užimtumą“. Jei pasirinktomis dienomis aikštelėje vietų yra, jūs išvysite rezervacijos formą ir kainą už pasirinktą laikotarpį. Norėdami tęsti rezervaciją, jūs turėsite pateikti privalomus kontaktinius duomenis ir atlikti mokėjimą.
          </p>
        </div>
        <div className="imageBox">
          <img
            src={parking1Jpg}
            className="imgSize"
            loading="lazy"
            alt="Pigus parkavimas prie Kauno oro uosto"
            srcSet={`
              ${parking1Webp480} 480w,
              ${parking1Webp768} 768w,
              ${parking1Webp1024} 1024w
            `}
            sizes="(max-width: 768px) 100vw, 50vw"
          />
        </div>
      </article>
      <article className="textCard">
        <div className="imageBox">
          <img
            src={parking2Jpg}
            className="imgSize"
            loading="lazy"
            alt="Aikštelė prie Kauno oro uosto"
            srcSet={`
              ${parking2Webp480} 480w,
              ${parking2Webp768} 768w,
              ${parking2Webp1024} 1024w
            `}
            sizes="(max-width: 768px) 100vw, 50vw"
          />
        </div>
        <div className="textCardText">
          <h2 className="header">Atvykimas į aikštelę</h2>
          <p className="body">
            Atvykus prie aikštelės, privažiuokite prie įvažiavimo ženklu pažymėto pakeliamo užtvaro. Privažiavę užtvarą, skambinkite telefono numeriu, nurodytu ant užtvaro (SVARBU - skambinkite iš to telefono numerio, kurį nurodėte atlikdami rezervaciją). Paskambinus užtvaras automatiškai pasikels ir jūs galėsite palikti automobilį laisvoje stovėjimo vietoje.
          </p>
        </div>
      </article>
      <article className="textCard">
        <div className="textCardText">
          <h2 className="header">Kaip pasiekti oro uostą?</h2>
          <p className="body">
            Mūsų aikštelė yra nutolusi 800m atstumu nuo Kauno oro uosto (~11min kelio pėsčiomis). Palikę automobilį, oro uostą pasiekti galite eidami tulpių arba pievų gatvėmis. Šios gatvės yra ramios, asfaltuotos ir apšviestos.
          </p>
        </div>
        <div className="imageBox">
          <img
            src={parking3Jpg}
            className="imgSize"
            loading="lazy"
            alt="Automobilių parkingas prie Kauno oro uosto"
            srcSet={`
              ${parking3Webp480} 480w,
              ${parking3Webp768} 768w,
              ${parking3Webp1024} 1024w
            `}
            sizes="(max-width: 768px) 100vw, 50vw"
          />
        </div>
      </article>
    </section>
  );
}

export default HowToUse;
