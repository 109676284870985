import GoogleMapsIcon from "../../images/GoogleMapsIcon.svg";
import WazeIcon from "../../images/WazeIcon.svg";
import LocationIcon from "../../images/location.svg";
import "./HowToFind.css"

const HowToFind = () => {
  const address = "Tulpių g. 20B, Karmėlava, Kauno rajonas";
  const latitude = 54.96814407208941;
  const longitude = 24.06454444182053;

  const handleGoogleMaps = () => {
    window.open(`https://www.google.com/maps?q=${latitude},${longitude}`);
  };

  const handleWaze = () => {
    window.open(`https://www.waze.com/ul?ll=${latitude},${longitude}`);
  };

  return (
    <section id="howtofind">
      <article className="navigationsContainer">
        <div className="directionsText">
          <div className="flexRow">
            <img src={LocationIcon} className="locationIcon" loading="lazy" alt="Pigus parkavimas prie Kauno oro uosto" />
            <address className="address">{address}</address>
          </div>
          <p>
            Automobilių parkavimo aikštelė prie <strong>Kauno oro uosto</strong>. Atstumas iki oro uosto – 800 m.
            Teritorija apšviesta, stebima vaizdo kameromis.
          </p>
        </div>

        <div className="navigationIconsContainer">
          <div className="flexRow navigation" role="button" onClick={handleGoogleMaps}>
            <img src={GoogleMapsIcon} className="navIcon" loading="lazy" alt="Google maps navigacija į aikštelę prie Kauno oro uosto" />
            <div>Google Maps navigacija</div>
          </div>
          <div className="flexRow navigation" role="button" onClick={handleWaze}>
            <img src={WazeIcon} loading="lazy" className="navIcon" alt="Waze navigacija į aikštelę prie Kauno oro uosto" />
            <div>Waze navigacija</div>
          </div>
        </div>
      </article>

      <div className="map-container">
        <iframe
          loading="lazy"
          className="google-map"
          title="Kauno Oro Uostas Parkavimo Aikštelė"
          src="https://www.google.com/maps/embed/v1/place?key=AIzaSyA5NKtZFkNxzDO75yL5NouClTq1K_VvIPQ&q=ParkNFly,+Kaunas&zoom=16"
          referrerPolicy="no-referrer-when-downgrade"
          allowFullScreen
        ></iframe>
      </div>
    </section>
  );
};

export default HowToFind