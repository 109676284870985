import WazeIcon from "../../images/WazeIcon-footer.svg";
import GoogleMapsIcon from "../../images/GoogleMapsIcon-footer.svg"
import PhoneIcon from "../../images/PhoneIcon.svg"
import MailIcon from "../../images/MailIcon.svg"
import "./Footer.css";

const Footer = () => {
  const latitude = 54.96814407208941;
  const longitude = 24.06454444182053;

  const handleGoogleMaps = () => {
    window.open(`https://www.google.com/maps?q=${latitude},${longitude}`);
  };

  const handleWaze = () => {
    window.open(`https://www.waze.com/ul?ll=${latitude},${longitude}`);
  };

  return (
    <footer className="footer">
      <div className="footerContent">
        <div>
          <ul>
            <li><h4>Kontaktai</h4></li>
            <li><img src={PhoneIcon} className="contactsIcon" loading="lazy" alt="Telefonas" /> +370 600 11 103</li>
            <li><img src={MailIcon} className="contactsIcon" loading="lazy" alt="Email" /> info@parknfly.lt</li>
          </ul>
        </div>
        <ul className="social-icon">
          <li className="social-icon__item">
            <div className="social-icon__link" onClick={handleGoogleMaps}>
              <img src={GoogleMapsIcon} className="footerIcon" loading="lazy" alt="Google maps navigacija į aikštelę prie Kauno oro uosto" />
            </div>
          </li>
          <li className="social-icon__item">
            <div className="social-icon__link" onClick={handleWaze}>
              <img src={WazeIcon} className="footerIcon" loading="lazy" alt="Waze navigacija į aikštelę prie Kauno oro uosto" />
            </div>
          </li>
        </ul>
        <div>
          <ul>
            <li>Rekvizitai</li>
            <li>Parkavimo paslauga, MB</li>
            <li>Įmonės kodas: 306671589</li>
            <li></li>
          </ul>
        </div>
      </div>
      <p>&copy;{new Date().getFullYear()} ParkNFly | All Rights Reserved</p>
    </footer>
  );
}

export default Footer;
